@import '../helpers/variables';
@import '../mixins';

@mixin hero-v2-banner() {
  background-color: $navy-dark;
  overflow: hidden;

  &__container {
    display: grid;
    grid-column-gap: 100px;
    grid-template-areas: "hero-v2--background"
                         "hero-v2--content";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin: 0 auto;

    max-width: $breakpoint-large;
    padding-left: 3rem;
    padding-right: 3rem;

    @include max-desktop {
      padding-left: 0;
      padding-right: 0;
    }

    @include min-desktop {
      grid-column-gap: 100px;
      grid-template-areas: "hero-v2--content hero-v2--background";
      grid-template-columns: 2fr 2fr;
    }
  }

  &__background {
    grid-area: hero-v2--background;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 0;

    @include max-desktop {
      height: 200px;
    }

    @include min-desktop {
      margin-right: -150px;
      padding-right: 150px;
      transform: skew(-190deg);
      transform-origin: bottom;
    }

    @include min-large {
      margin-right: unset;
      padding-right: unset;
    }

    &::before {
      background-clip: padding-box;
      background-color: $white;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      bottom: 0;
      content: " ";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
      @include min-desktop {
        background-position: 66% center;
        background-size: cover;
        transform: skew(-1 * -190deg);
        transform-origin: bottom;
        width: 120%;
      }
    }

    &__gallery {
      height: 100%;

      &__image {
        height: 100%;

        > img {
          height: 100%;
          object-fit: contain;
          width: 100%;
          @include min-desktop {
            height: 150%;
            margin: -25%;
            width: 150%;
          }
        }
      }
    }
  }

  &__content {
    grid-area: hero-v2--content;
    padding: 50px;
    position: relative;
    z-index: +4;

    &__title {
      color: $white;
    }
  }

  &__form {
    margin-top: 200px;

    @include min-tablet {
      margin-top: 40px;
    }

    &__overlay {
      align-items: center;
      background-color: transparentize($black , 0.4);
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;

      &--success & {
        background-color: $white;
      }

      .success-message {
        font-size: 18px;
        font-weight: bold;
        padding: 0 20px;
        text-align: center;
      }
    }
  }
}

.hero-v2 {
  @include hero-v2-banner()
}
