// Imports
@import '../helpers/variables';
@import '../mixins';

.wcards {
  display: flex;
  justify-content: space-evenly;

  @include max-desktop {
    flex-wrap: wrap;
  }

  @include max-tablet {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &__item {
    background-color: $white;
    border-bottom: 2px solid $grey;
    border-radius: 4px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 0 20px;
    padding: 10px;
    text-align: center;
    text-decoration: none;

    &--small {
      max-width: 16rem;

        @include max-mobile {
          max-width: none;
        }
    }

    &:hover {
      box-shadow: 0 0 14px 5px rgba(0, 0, 0, 0.2);
    }

    @include max-desktop {
      flex-basis: 45%;
      flex-grow: 0;
      margin: 0 0 20px;
    }

  }

  &__icon {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    position: relative;
    width: 60%;

    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    svg {
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__image {
    margin: 0 auto 20px;
    position: relative;
    width: 100%;

    &::after {
      content: '';
      display: block;
      padding-bottom: 80%;
    }

    img {
      left: 0;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__text {
    color: $navy-dark;
    font-weight: bold;
  }

  &--stacked {
    flex-wrap: wrap;

    .wcards__item {
      flex-basis: 45%;
      flex-grow: 0;
      margin: 0 0 20px;
    }

    @include max-tablet {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
}

.ltb-switch {
  border: 2px solid $navy-dark;
  border-radius: 4px;
  display: inline-block;
  margin: 0 auto 30px;

  &__button {
    background-color: transparent;
    border: 0;
    color: $navy-dark;
    cursor: pointer;
    padding: 10px 20px;
    width: 180px;

    @include max-mobile {
      width: 100%;
    }

    &--active {
      background-color: $navy-dark;
      color: $white;
    }
  }
}
