// Imports
@import '../helpers/variables';
@import '../mixins';
@import '../components/wcards';
@import '../components/hero-v2';

.hero-v2 {
  &-home {
    &__content {
      min-height: 430px;
    }

    &__background {
      &::before {
        background-image: url('../img/home/frog-hero.webp');
        background-size: cover;
      }
    }
  }
}

.blue-circle-gradient {
  background: radial-gradient(circle, $navy 0%, $navy-dark 35%);
}

.homehero {
  background-color: $navy-dark;
  color: $white;

  &__row {
    @include max-desktop {
      flex-direction: column-reverse;
    }
  }

  .row &__bg-col {
    background-image: url('../img/home/frog-hero.webp');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 450px;

  }
}

.homehero-mobile {
  background-image: url('../img/home/frog-hero.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
}

.makemodel-search {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include max-mobile {
    flex-direction: column;
  }

  &__title {
    color: $navy-dark;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;

    @include max-tablet {
      flex-basis: 100%;
    }
  }

  &__dropdown {
    margin-bottom: 0;
    margin-left: 15px;
    max-width: 150px;

    @include min-large {
      max-width: 250px;
    }

    @include max-mobile {
      margin-left: 0;
      margin-top: 10px;
      max-width: 100%;
    }
  }

  &__button {
    margin-left: 15px;
    max-width: 100px;
    white-space: nowrap;
    width: 100%;

    @include max-mobile {
      margin-left: 0;
      margin-top: 10px;
      max-width: 150px;
    }
  }

  .buffer {
    border-radius: 6px;
  }
}

.title-icon {
  margin-bottom: 20px;
  position: relative;
  width: 33%;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  svg {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  @include max-tablet {
    margin: 0 auto 20px;
  }
}

.green-future {
  background-image: url('../img/home/uk_map.svg');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 40%;
  min-height: 450px;

  @include max-tablet {
    background-size: contain;
  }

  &__item {
    display: flex;
    justify-content: left;

    @include max-desktop {
      justify-content: center;
    }

  }

  &__img {
    width: 35rem;
  }
}

.green-list {
  display: flex;
  flex-wrap: wrap;

  &__key-figure {
    font-size: 35px;
  }

  &__item {
    flex-basis: 25%;
    padding: 20px;
    position: relative;

    &::before {
      background-color: $green;
      content: '';
      height: 35px;
      left: 0;
      position: absolute;
      top: 25px;
      width: 1px;
    }

    @include max-tablet {
      flex-basis: 50%;
    }

    @include max-mobile {
      flex-basis: 100%;
    }
  }
}



